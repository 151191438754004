.deviceModal {
	:global {
		.ms-Dialog-main {
			width: 1100px;
			height: 90vh;
			max-width: 1100px;
			max-height: 90vh;
			overflow: hidden;

			.ms-Modal-scrollableContent {
				height: 100%;
				form {
					height: 100%;
					overflow-y: inherit;
					>div:first-of-type{
						>div:first-of-type{
							padding: 15px 20px;
						}
					}
					.loading-block {
						height: calc(100% - 140px)!important;
					}
					.basic-modal-content {
						height: calc(100% - 102px);
						overflow-y: auto;
					}
				}
			}

			.actions {
				margin: 15px 24px;
				display: flex;
				& > button {
					margin-right: 10px;
				}
			}
			.loading-block {
				height: auto;
			}
			.ms-Pivot {
				display: flex;
				margin-top: 20px;

				&>button {
					flex: 1;
				}
				.ms-Pivot-link {
					background-color: rgb(243, 242, 241);
					&.is-selected {
						background-color: white;
						border-bottom: 0;
					}
					&:hover {
						&::before {
							background-color: rgb(0, 150, 136);
						}
					}
				}
			}

			div[role="tabpanel"] {
				padding-top: 10px;
				// height: calc(92vh - 520px);
				overflow-y: auto;
				.flex-row {
					& > div {
						flex: 1;
					}
				}
			}

			.table-title {
				& > div {
					display: flex;
					align-items: center;
				}
				display: flex;
				.show-table{
					margin-top: 2px;
					&:hover{
						background-color: transparent;
					}
				}
				.ms-Button {
					padding: 0;
					margin-left: 10px;
					min-width: 30px;
					height: 24px;
					border: 0;
					i {
						font-size: 14px;
					}
				}
				b{
					cursor: pointer;
				}
			}
			.scrollable-defects-wrapper{
				position: relative;
				height: 175px;
				.ms-ScrollablePane--contentContainer{
					overflow-x: hidden;
				}
			}


			.battery-block {
				width: 100%;
				margin-bottom: 30px;
				&__header {
					display: flex;
					align-items: center;
					.ms-Button {
						padding: 0;
						margin-left: 10px;
						min-width: 30px;
						height: 24px;
						border: 0;
						i {
							font-size: 18px;
						}
					}
				}
			}
		}
	}

	.width170{
		min-width: 170px;
	}
}

.deviceIsLockedModal {
	:global {
		.ms-Dialog-main {
			height: 190px;
			width: 400px;
			div {
				height: auto !important;
			}
			.basic-modal-content {
				min-height: auto;
				padding: 10px;
			}
		}
	}
}

.companyPicker{
	flex: 0.43!important;
}